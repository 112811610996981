<template>
  <div class="pt-16">
    <div
      class="w-full h-50vh md:h-25vh lg:h-50vh px-4 md:px-0 py-12 mx-auto text-left bg-fixed bg-contain bg-no-repeat flex items-center justify-end"
      :style="`background-image: url(${bgImage})`"
    >
      <!-- <div class="w-6/12 py-12 px-4 md:px-0 text-4xl font-semibold">
        <div class="w-max p-4 text-secondary">
          College Prep​
        </div>
      </div> -->
    </div>
    <div
      class="md:w-768 lg:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
    >
      <div class="w-full text-textBlack text-left">
        <!-- <h1 class="mb-6 text-4xl text-primary font-semibold">
          neo’s testing tools​
        </h1> -->
        <h1 class="mb-2 text-2xl text-primary font-semibold">College Prep ​</h1>
        <!-- <h3 class="text-xl">
          With neo’s testing tools, its easy to get started and keep your
          learners on the right path.​
        </h3> -->
        <p class="text-base text-justify">
          With neo, high school learners work first to develop general,
          school subject, and social situation English skills while developing
          strong reading skills. College-bound students will progress to acquire
          the advanced skills necessary for college-level work in English. ​
        </p>
        <h1 class="mt-10 mb-0 text-xl text-primary font-semibold text-center">
          High School Course
        </h1>
        <ul
          class="flex flex-wrap md:flex-nowrap justify-between text-textBlack"
        >
          <li class="w-full md:w-1/5 bg-softGrey py-4 px-6 mt-2 md:mt-6">
            <div class="h-20 flex items-center justify-center">
              <h5 class="font-semibold text-pulpBlue text-center text-lg">
                11 Certificate Levels​
              </h5>
            </div>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              Learners are placed in one of 11 CEFR levels based on current
              skills. Each certificate can be completed in 36 hours or less.​
            </p>
          </li>
          <li
            class="w-full md:w-1/5 bg-softGrey py-4 px-6 md:ml-2 mt-2 md:mt-6"
          >
            <div class="h-20 flex items-center justify-center">
              <h5 class="font-semibold text-pulpBlue text-center text-base">
                Speech Recognition Exercises ​
              </h5>
            </div>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              Students can practice their pronunciation skills with neo’s
              advanced speech recognition (SR) technology. SR exercises
              continuously improve learners’ oral skills. ​
            </p>
          </li>
          <li
            class="w-full md:w-1/5 bg-softGrey py-4 px-6 md:mx-2 mt-2 md:mt-6"
          >
            <div class="h-20 flex items-center justify-center">
              <h5 class="font-semibold text-pulpBlue text-center text-lg">
                Grammar and Reading Exercises​
              </h5>
            </div>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              Learners build important grammar and reading skills that prove
              invaluable in middle school and high school, and as your students
              prepare for higher education.​
            </p>
          </li>
          <li
            class="w-full md:w-1/5 bg-softGrey py-4 px-6 md:mr-2 mt-2 md:mt-6"
          >
            <div class="h-20 flex items-center justify-center">
              <h5 class="font-semibold text-pulpBlue text-center text-lg">
                Mastery Tests​​
              </h5>
            </div>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              neo’s Mastery Tests confirm a student’s grasp of key CEFR 'can-do
              statements' studied so far. Each unit continues until all CEFR
              level 'can-do statements' are achieved​.
            </p>
          </li>
          <li class="w-full md:w-1/5 bg-softGrey py-4 px-6 mt-2 md:mt-6">
            <div class="h-20 flex items-center justify-center">
              <h5 class="font-semibold text-pulpBlue text-center text-lg">
                Level Tests​​
              </h5>
            </div>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              At the end of each level, learners take a Certificate Test
              covering key language and concepts from the entire CEFR level.​
            </p>
          </li>
        </ul>
      </div>
    </div>
    <ul class="mb-20 flex justify-center">
      <a
        class="bg-softBlue text-secondary px-4 py-1 mr-1 cursor-pointer underline font-light"
        href="https://cms.nexgenenglishonline.co/uploads/files/A1%20Nexgen%20Website%20Media%20Lib/Brochures%20NW/neoPrep%2B%20Brochure.pdf"
        target="_blank"
      >
        Download more info
      </a>
      <!-- <li
        class="bg-softBlue text-secondary px-4 py-1 ml-1 cursor-pointer underline font-light"
      >
        neoJr+ Video
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bgImageUrl: require("@/assets/images/icons/6.college-prep.jpg"),
      isMobile: window.innerWidth <= 768, // Adjust the breakpoint as needed
      mobileImageUrl: require("@/assets/images/mobile/Option-1---highschool-720x1280-higher-res.jpg"),
      desktopImageUrl: require("@/assets/images/icons/6.college-prep.jpg"),
    };
  },
  computed: {
    bgImage() {
      return this.isMobile ? this.mobileImageUrl : this.desktopImageUrl;
    },
  },
};
</script>

<style>
</style>